





























import { Vue, Component } from "vue-property-decorator";
import { API_AUTH_CHANGE_PASSWORD, API_AUTH_SEND_AUTH_CODE } from "@/configs/Apis";
import { MSG_CHANGEPASSWORD_SUCCESS, MSG_SENDAUTHCODE } from "@/configs/Consts";

import { isEmpty, showError, showSuccess } from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import AuthMainLayout from "@/views/auth/AuthMainLayout.vue";

    @Component({
      components: { AuthMainLayout }
    })
export default class FindPassword extends Vue {
        isLoading = false;

        sendingValidCode = false;
        counterNum = 60;
        startInput = false;

        userData = {
          // loginName: "",
          email: "",
          validateCode: "",
          newPassword: "",
          confirmNewPassowrd: ""
        };

        mounted() {
          setTimeout(() => this.startInput = true, 1000);
        }

        get rules() {
          return {
            // "loginName": [
            //     {required: true, message: "请输入用户名", trigger: "blur"}
            // ],
            email: [
              { required: true, message: "请输入邮箱", trigger: "blur" },
              { required: true, type: "email", message: "邮箱格式不正确", trigger: "blur" }
            ],
            validateCode: [
              { required: true, message: "请输入验证码", trigger: "blur" }
              /* {
                        validator: (rule: any, value: string, callback: Function) => {
                            Ajax.queryData(API_AUTH_SEND_AUTH_CODE, {}).then(() => {
                                callback();
                                showSuccess(MSG_VALIDATE_CODE);
                            }).catch(({message} = {message: ""}) => showError(message));
                        },
                        trigger: "blur"
                    } */
            ],
            newPassword: [
              { required: true, message: "请输入新密码", trigger: "blur" }
            ],
            confirmNewPassowrd: [
              { required: true, message: "请再一次输入密码", trigger: "blur" },
              {
                validator: (rule: any, value: string, callback: Function) => {
                  if (value !== this.userData.newPassword) {
                    callback(new Error("两次输入的密码不一致"));
                  } else {
                    callback();
                  }
                },
                trigger: "blur"
              }
            ]
          };
        }

        sendValidCode() {
          const result = [/* 0,  */0];
          let pos = 0;
          (this.$refs.form as any).validateField([/* "loginName", */"email"], (message: string) => {
            if (!isEmpty(message)) {
              return;
            }
            result[pos++] = 1;
            if (result.every(i => i > 0)) {
              // 发送验证码
              const postedParams = {
                // loginName: this.userData.loginName,
                email: this.userData.email
              };
              this.sendingValidCode = true;
              Ajax.queryData(API_AUTH_SEND_AUTH_CODE, postedParams, "POST", false).then(() => showSuccess(MSG_SENDAUTHCODE)).catch(({ message } = { message: "" }) => {
                this.sendingValidCode = false;
                showError(message);
              });
              this.startCountDown();
            }
          });
        }

        startCountDown() {
          const token = setInterval(() => {
            this.counterNum -= 1;
            if (this.counterNum <= 0) {
              clearInterval(token);
              this.counterNum = 30;
              this.sendingValidCode = false;
            }
          }, 1000);
        }

        submitData() {
          (this.$refs.form as any).validate((isValid: boolean) => {
            if (!isValid) {
              return;
            }
            const postedData = {
              // loginName: this.userData.loginName,
              email: this.userData.email,
              password: this.userData.newPassword,
              authCode: this.userData.validateCode
            };
            Ajax.saveData(API_AUTH_CHANGE_PASSWORD, postedData)
              .then(() => showSuccess(MSG_CHANGEPASSWORD_SUCCESS))
              .then(() => this.$router.push("login"))
              .catch(({ message } = { message: "" }) => showError(message));
          });
        }
}
